<template>
  <div class="container">
    <div class="back" @click="back">
      <icon-font class="icon" name="arrow-left" size="13px" color="#1465CF"></icon-font>
      <span class="back-text">返回</span>
    </div>
    <!-- 活动详情 -->
    <div class="activity-detail">
      <div class="title">{{ detail.activityName }}</div>
      <div class="msg-line">
        <span class="label">活动地址：</span>
        <span class="value">{{ detail.address.split('|')[1]}}</span>
      </div>
      <div class="msg-line">
        <span class="label">活动时间：</span>
        <span class="value">{{ detail.startTime.substring(0, 16).replace(/-/g, '/') }} — {{ detail.endTime.substring(0, 16).replace(/-/g, '/') }}</span>
      </div>
      <div class="msg-line">
        <span class="label">报名截止：</span>
        <span class="value">{{ detail.joinEndTime.substring(0, 16).replace(/-/g, '/') }}</span>
      </div>
      <div class="msg-line" v-if="detail.isOpenInsurance === 2">
        <span class="label">开启保险链接时间：</span>
        <span class="value">{{ detail.insuranceOpenTime.substring(0, 16).replace(/-/g, '/') }}</span>
      </div>
    </div>

    <!-- 活动分组 -->
    <div class="activity-group">
      <div class="group-item" v-for="(item) in groupList" :key="item.id" @click="toggleGroup(item)">
        <div class="group-name">{{ item.activityFeeBranchName }}</div>
        <div class="bottom-line" v-if="currentGroupId === item.id"></div>
      </div>
    </div>

    <!-- 报名用户列表 -->
    <div class="empty" v-if="changeLoad" v-loading="true"></div>
    <div class="joiner-data" v-else>
      <div class="table-data">
        <div class="header">
          <div class="title">成功报名用户</div>
          <div class="download">
            <div v-if="detail.signCount > 1" class="download__item" @click="getActivitySign">
              <icon-font class="icon" name="save" size="16px" color="#333"></icon-font>
              <div>签到详情</div>
            </div>
            <div class="download__item" @click="getAllGroupMemberData">
              <icon-font class="icon" name="save" size="16px" color="#333"></icon-font>
              <div>下载</div>
            </div>
          </div>
        </div>
        <div class="main">
          <el-table
            :data="successList"
            style="width: 100%"
            border
            :header-cell-style="headerCellStyle"
            empty-text="暂无报名用户">
            <el-table-column
              :fixed="successList.length > 0 ? true : false"
              align="center"
              width="100"
              type="index">
              <template slot="header">
                <div class="table-header">序号</div>
              </template>
            </el-table-column>
            <el-table-column
              :fixed="successList.length > 0 ? true : false"
              prop="nickname"
              align="center"
              width="120">
              <template slot="header">
                <div class="table-header">用户昵称</div>
              </template>
              <template slot-scope="scope">
                <div class="table-text">{{ scope.row.nickname }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="username"
              align="center"
              width="148">
              <template slot="header">
                <div class="table-header">用户拿铁号</div>
              </template>
              <template slot-scope="scope">
                <span class="table-text">{{ scope.row.username }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              align="center"
              width="178">
              <template slot="header">
                <div class="table-header">开始报名/候补时间</div>
              </template>
              <template slot-scope="scope">
                <div class="table-text">
                  <div>{{ scope.row.createTime }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="username"
              align="center"
              width="148">
              <template slot="header">
                <div class="table-header">占位时间</div>
              </template>
              <template slot-scope="scope">
                <div class="table-text">
                  <div>{{ scope.row.placeSuccessTime }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="signTime"
              align="center"
              width="120">
              <template slot="header">
                <div class="table-header">是否签到</div>
              </template>
              <template slot-scope="scope">
                <div class="table-text">
                  <div>{{ scope.row.signTime ? '是' : '否' }}</div>
                </div>
              </template>
            </el-table-column>
            <!-- 用户自定义填写项 -->
            <template v-if="detail.joinCollectInformationResponseVoList">
              <el-table-column
                v-for="(item, index) in detail.joinCollectInformationResponseVoList"
                :key="index"
                prop="signTime"
                align="center"
                width="120">
                <template slot="header">
                  <div class="table-header">{{ item.question }}</div>
                </template>
                <template slot-scope="scope">
                  <div class="table-text">
                    <div>{{ scope.row.newCollectInfoList ? scope.row.newCollectInfoList[index].answer : '' }}</div>
                  </div>
                </template>
              </el-table-column>
            </template>
            <template v-if="detail.isJointActivity === 2">
              <el-table-column
                prop="sourceClubName"
                align="center"
                width="120">
                <template slot="header">
                  <div class="table-header">流量来源</div>
                </template>
                <template slot-scope="scope">
                  <div class="table-header">{{ scope.row.sourceClubName }}</div>
                </template>
              </el-table-column>
            </template>
            <template v-if="isShowSuperShare">
                <el-table-column
                    prop="distributionUserName"
                    align="center"
                    width="120">
                    <template slot="header">
                      <div class="table-header">超级分享者</div>
                    </template>
                    <template slot-scope="scope">
                      <div class="table-header">{{ scope.row.distributionUserName }}</div>
                    </template>
                </el-table-column>
            </template>
            <el-table-column
              prop="firstJoin"
              align="center"
              width="120">
              <template slot="header">
                <div class="table-header">
                  <div>是否第一次</div>
                  <div>参加活动</div>
                </div>
              </template>
              <template slot-scope="scope">
                <span class="table-text">{{ scope.row.firstJoin ? '是' : '否' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="joinCount"
              align="center"
              width="120">
              <template slot="header">
                <div class="table-header">
                  <div>累计参与</div>
                  <div>活动次数</div>
                  <el-tooltip class="tooltip" effect="dark" content="截止当前活动" placement="top">
                    <i class="el-icon-warning-outline" style="color: #0584F9; font-size: 14px"/>
                  </el-tooltip>
                </div>
              </template>
              <template slot-scope="scope">
                <span class="table-text">{{ scope.row.joinCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="fans"
              align="center"
              width="120">
              <template slot="header">
                <div class="table-header">用户身份</div>
              </template>
              <template slot-scope="scope">
                <span class="table-text">{{ formatIdentity(scope.row.identity) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="nonMemberAmount"
              align="center"
              width="120">
              <template slot="header">
                <div class="table-header">本次付费</div>
              </template>
              <template slot-scope="scope">
                <span class="table-text">{{ scope.row.feeType === 1 || !scope.row.nonMemberAmount ? '-' :scope.row.nonMemberAmount.toFixed(2) + '元' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="isOpenDisclaimer"
              align="center"
              width="120">
              <template slot="header">
                <div class="table-header">是否同意免责声明</div>
              </template>
              <template slot-scope="scope">
                <span class="table-text">{{ scope.row.isOpenDisclaimer === 1 ? '未开启' : '是' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="detail.isOpenAccidentInsurance === 2"
              prop="collectInsurance"
              align="center"
              width="120">
              <template slot="header">
                <div class="table-header">保障领取</div>
              </template>
              <template slot-scope="scope">
                <span class="table-text1">{{ scope.row.collectInsurance.length === 0 ? '未领取' : scope.row.collectInsurance.join('；') }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="table-data">
        <div class="header">
          <div class="title">候补用户</div>
          <div class="download" @click="getAllGroupMemberData">
            <icon-font class="icon" name="save" size="16px" color="#333"></icon-font>
            <div>下载</div>
          </div>
        </div>
        <div class="main">
          <el-table
            :data="waitList"
            style="width: 100%"
            border
            :header-cell-style="headerCellStyle"
            empty-text="暂无候补用户">
            <el-table-column
              :fixed="waitList.length > 0 ? true : false"
              align="center"
              width="100"
              type="index">
              <template slot="header">
                <div class="table-header">候补排名</div>
              </template>
            </el-table-column>
            <el-table-column
              :fixed="waitList.length > 0 ? true : false"
              prop="nickname"
              align="center"
              width="120">
              <template slot="header">
                <div class="table-header">用户昵称</div>
              </template>
              <template slot-scope="scope">
                <div class="table-text">{{ scope.row.nickname }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="username"
              align="center"
              width="148">
              <template slot="header">
                <div class="table-header">用户拿铁号</div>
              </template>
              <template slot-scope="scope">
                <span class="table-text">{{ scope.row.username }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              align="center"
              width="178">
              <template slot="header">
                <div class="table-header">开始候补时间</div>
              </template>
              <template slot-scope="scope">
                <div class="table-text">
                  <div>{{ scope.row.createTime }}</div>
                </div>
              </template>
            </el-table-column>
            <!-- 用户自定义填写项 -->
            <template v-if="detail.joinCollectInformationResponseVoList">
              <el-table-column
                v-for="(item, index) in detail.joinCollectInformationResponseVoList"
                :key="index"
                prop="signTime"
                align="center"
                width="120">
                <template slot="header">
                  <div class="table-header">{{ item.question }}</div>
                </template>
                <template slot-scope="scope">
                  <div class="table-text">
                    <div>{{ scope.row.newCollectInfoList ? scope.row.newCollectInfoList[index].answer : '' }}</div>
                  </div>
                </template>
              </el-table-column>
            </template>
            <template v-if="detail.isJointActivity === 2">
              <el-table-column
                prop="sourceClubName"
                align="center"
                width="120">
                <template slot="header">
                  <div class="table-header">流量来源</div>
                </template>
                <template slot-scope="scope">
                  <div class="table-header">{{ scope.row.sourceClubName }}</div>
                </template>
              </el-table-column>
            </template>
            <template v-if="isShowSuperShare">
                <el-table-column
                    prop="distributionUserName"
                    align="center"
                    width="120">
                    <template slot="header">
                      <div class="table-header">超级分享者</div>
                    </template>
                    <template slot-scope="scope">
                      <div class="table-header">{{ scope.row.distributionUserName }}</div>
                    </template>
                </el-table-column>
            </template>
            <el-table-column
              prop="firstJoin"
              align="center"
              width="120">
              <template slot="header">
                <div class="table-header">
                  <div>是否第一次</div>
                  <div>参加活动</div>
                </div>
              </template>
              <template slot-scope="scope">
                <span class="table-text">{{ scope.row.firstJoin ? '是' : '否' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="joinCount"
              align="center"
              width="120">
              <template slot="header">
                <div class="table-header">
                  <div>累计参与</div>
                  <div>活动次数</div>
                  <el-tooltip class="tooltip" effect="dark" content="截止当前活动" placement="top">
                    <i class="el-icon-warning-outline" style="color: #0584F9; font-size: 14px"/>
                  </el-tooltip>
                </div>
              </template>
              <template slot-scope="scope">
                <span class="table-text">{{ scope.row.joinCount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="fans"
              align="center"
              width="120">
              <template slot="header">
                <div class="table-header">用户身份</div>
              </template>
              <template slot-scope="scope">
                <span class="table-text">{{ formatIdentity(scope.row.identity) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="nonMemberAmount"
              align="center"
              width="120">
              <template slot="header">
                <div class="table-header">本次付费</div>
              </template>
              <template slot-scope="scope">
                <span class="table-text">{{ scope.row.feeType === 1 || !scope.row.nonMemberAmount ? '-' : scope.row.nonMemberAmount.toFixed(2) + '元' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="isOpenDisclaimer"
              align="center"
              width="120">
              <template slot="header">
                <div class="table-header">是否同意免责声明</div>
              </template>
              <template slot-scope="scope">
                <span class="table-text">{{ scope.row.isOpenDisclaimer === 1 ? '未开启' : '是' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="detail.isOpenAccidentInsurance === 2"
              prop="collectInsurance"
              align="center"
              width="120">
              <template slot="header">
                <div class="table-header">保障领取</div>
              </template>
              <template slot-scope="scope">
                <span class="table-text1">{{ scope.row.collectInsurance.length === 0 ? '未领取' : scope.row.collectInsurance.join('；') }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import iconFont from '../../components/IconFont.vue'
  import Export2Excel from "@/utils/Export2Excel.js"
  export default {
    data() {
      return {
        detail: {
          activityName: '活动名称',
          address: '活动地址｜活动地址',
          startTime: '2022/01/01 00:00',
          endTime: '2022/01/01 00:00',
          joinEndTime: '2022/01/01 00:00'
        },
        currentGroupId: 0,
        currentGroupName: '',
        changeLoad: false,
        emptySuccess: false,
        emptyWait: false,
        groupList: [],
        successList: [],
        isShowSuperShare: false,
        waitList: [],
        headerCellStyle: {
          height: '48px',
          backgroundColor: '#F3F5F8',
          padding: '4px 0',
        },
      }
    },

    components: {
      iconFont
    },

    methods: {
      back() {
        this.$emit('back')
      },

      init(data) {
        this.detail = data
        if (this.detail.isOpenDistribution == 2) {
            this.isShowSuperShare = true
        }
        this.getActivityGroup()
      },

      formatIdentity(identity) {
        if(identity === 1) return '主理人'
        if(identity === 2) return '管理员'
        if(identity === 3) return '成员'
        if(identity === 4) return '粉丝'
        if(identity === 5) return '路人'
        if(identity === 6) return '会员-畅玩'
      },

      // 切换分组
      toggleGroup(data) {
        this.currentGroupId = data.id
        this.currentGroupName = data.activityFeeBranchName
        this.emptySuccess = this.emptyWait = false
        this.successList = this.waitList = []
        this.changeLoad = true
        this.getActivityGroupUser()
        // this.getActivityGroupAlternateUser()
      },

      // 获取活动分组
      async getActivityGroup() {
        try {
          let res = await this.$request.get(`/clubData/getActivityGroup?activityId=${this.detail.id}`)
          this.groupList = res.data
          this.toggleGroup(res.data[0])
        } catch (error) {
          console.log('报错', error)
        }
      },

      // 获取活动分组用户
      async getActivityGroupUser() {
        try {
          let res = await this.$request.get(`/clubData/getActivityGroupUser?activityId=${this.detail.id}&activityGroupId=${this.currentGroupId}`)
          this.getActivityGroupAlternateUser()
          // console.log('获取活动分组用户', res)
          let data = res.data
          let joinCollectInformationResponseVoList = this.detail.joinCollectInformationResponseVoList
          for(let i = 0; i < data.length; i++) {
            let newCollectInfoList = JSON.parse(JSON.stringify(joinCollectInformationResponseVoList))
            for(let m = 0; m < newCollectInfoList.length; m++) {
              for(let j = 0; j < data[i].joinCollectInformationResponseVoList.length; j++) {
                if(newCollectInfoList[m].id == data[i].joinCollectInformationResponseVoList[j].id) {
                  newCollectInfoList[m].answer = data[i].joinCollectInformationResponseVoList[j].answer
                  break
                }
              }
            }
            data[i].newCollectInfoList = newCollectInfoList
          }
          this.successList = data
          // 超级分享存在开了又关的情况，所以必须判断每个报名用户是不是走了超级分享
          if (!this.isShowSuperShare) {
            this.successList.forEach(element => {
                if (element.distributionUserName) {
                    this.isShowSuperShare = true
                }
            });
          }
          if(res.data.length > 0) {
            this.emptySuccess = false
          } else {
            this.emptySuccess = true
          }
        } catch (error) {
          console.log('获取活动分组用户err', error)
        }
        this.changeLoad = false
      },

      // 获取活动分组候补用户
      async getActivityGroupAlternateUser() {
        try {
          let res = await this.$request.get(`/clubData/getActivityGroupAlternateUser?activityId=${this.detail.id}&activityGroupId=${this.currentGroupId}`)
          // console.log('获取活动分组候补用户', res)
          let data = res.data
          let joinCollectInformationResponseVoList = this.detail.joinCollectInformationResponseVoList
          for(let i = 0; i < data.length; i++) {
            let newCollectInfoList = JSON.parse(JSON.stringify(joinCollectInformationResponseVoList))
            for(let m = 0; m < newCollectInfoList.length; m++) {
              for(let j = 0; j < data[i].joinCollectInformationResponseVoList.length; j++) {
                if(newCollectInfoList[m].id == data[i].joinCollectInformationResponseVoList[j].id) {
                  newCollectInfoList[m].answer = data[i].joinCollectInformationResponseVoList[j].answer
                  break
                }
              }
            }
            data[i].newCollectInfoList = newCollectInfoList
          }
          this.waitList = data
          if (!this.isShowSuperShare) {
            this.waitList.forEach(element => {
                if (element.distributionUserName) {
                    this.isShowSuperShare = true
                }
            });
          }
          if(res.data.length > 0) {
            this.emptyWait = false
          } else {
            this.emptyWait = true
          }
        } catch (error) {
          // console.log('获取活动分组候补用户err',error)
        }
        this.changeLoad = false
      },

      // 下载签到详情
      async getActivitySign() {
        try {
          let res = await this.$request.get('/dataExport/activitySign/' + this.detail.id, {}, 'blob')
          console.log(res)
          let data = res
          const link = document.createElement('a')
          let blob = new Blob([data], { type: 'application/vnd.ms-excel;charset=utf-8' })
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          link.download = `活动签到详情_${this.detail.startTime.substring(0, 10).replace(/-/g, '')}_${this.detail.activityName}`
          document.body.appendChild(link)
          link.click()
          window.URL.revokeObjectURL(link.href)
          document.body.removeChild(link)
        } catch (error) {
          console.log('报错', error)
        }
      },

      // 获取所有活动分组报名情况的数据
      async getAllGroupMemberData() {
        try {
          let data = []
          let multiHeader = []
          let sheetname = []
          let merges = []

          let sHeader = ['用户昵称','用户拿铁号']
          let mHeader = ['占位成功时间', '是否签到']
          let eHeader = ['是否第一次参加活动','累计参与活动次数','用户身份','本次付费', '是否同意免责声明']
          let insuranceHeader = this.detail.isOpenAccidentInsurance === 2 ? ['保障领取'] : []
          let customHeader = []
          let sFilter = ['index', 'nickname','username','createTime']
          let mFilter = ['placeSuccessTime', 'signTime']
          let eFilter = ['firstJoin','joinCount','identity','nonMemberAmount', 'isOpenDisclaimer']
          let insuranceFilter = this.detail.isOpenAccidentInsurance === 2 ? ['collectInsurance'] : []
          let customFilter = []
          for(let i = 0; i < this.detail.joinCollectInformationResponseVoList.length; i++) {
            customHeader.push(this.detail.joinCollectInformationResponseVoList[i].question)
            customFilter.push(`custom${i+1}`)
          }
          let jointHeader = []
          let jointFilter = []
          if (this.detail.isJointActivity === 2) {
            jointHeader = ['流量来源']
            jointFilter = ['sourceClubName']
          }
          let shareHeader = []
          let shareFilter = []
          if (this.isShowSuperShare) {
            shareHeader = ['超级分享者']
            shareFilter = ['distributionUserName']
          }
          // 报名成功用户
          let header = ['序号', ...sHeader, '开始报名/候补时间', ...mHeader, ...customHeader, ...jointHeader, ...shareHeader, ...eHeader, ...insuranceHeader]
          let filterVal = [...sFilter, ...mFilter, ...customFilter, ...jointFilter, ...shareFilter, ...eFilter, ...insuranceFilter]
          // 候补用户
          let wHeader = ['候补排名', ...sHeader, '开始候补时间', ...customHeader, ...jointHeader, ...shareHeader, ...eHeader, ...insuranceHeader]
          let wFilterVal = [...sFilter, ...customFilter, ...jointFilter, ...shareFilter, ...eFilter, ...insuranceFilter]
          // 遍历获取所有分组报名数据
          for(let i = 0; i < this.groupList.length; i++) {
            let succRes = await this.$request.get(`/clubData/getActivityGroupUser?activityId=${this.detail.id}&activityGroupId=${this.groupList[i].id}`)
            let succSheelData = this.createSheelData(this.groupList[i], succRes.data, header, filterVal, true)
            data.push(succSheelData.data)
            multiHeader.push(succSheelData.multiHeader)
            sheetname.push(succSheelData.sheetname)
            merges.push(succSheelData.merges)

            let waitRes = await this.$request.get(`/clubData/getActivityGroupAlternateUser?activityId=${this.detail.id}&activityGroupId=${this.groupList[i].id}`)
            let waitSheelData = this.createSheelData(this.groupList[i], waitRes.data, wHeader, wFilterVal, false)
            data.push(waitSheelData.data)
            multiHeader.push(waitSheelData.multiHeader)
            sheetname.push(waitSheelData.sheetname)
            merges.push(waitSheelData.merges)
          }

          Export2Excel.export_json_to_excel({
            multiHeader: multiHeader,
            filename: `${this.detail.startTime.substring(5, 10)}「${this.detail.activityName}」报名信息`,
            sheetname: sheetname,
            data: data,
            merges: merges
          })
        } catch (error) {
          console.log('生成所有数据表格失败', error)
        }
      },

      // 生成单个sheel表格的数据
      createSheelData(detail = null, datalist = [], header = [], filterVal = [], isSuccess = true) {
        let returnData = {
          multiHeader: null,
          data: null,
          merges: null,
          sheetname: ''
        }
        let joinCollectInformationResponseVoList = this.detail.joinCollectInformationResponseVoList
        for(let i = 0; i < datalist.length; i++) {
          let newCollectInfoList = JSON.parse(JSON.stringify(joinCollectInformationResponseVoList))
          for(let m = 0; m < newCollectInfoList.length; m++) {
            for(let j = 0; j < datalist[i].joinCollectInformationResponseVoList.length; j++) {
              if(newCollectInfoList[m].id == datalist[i].joinCollectInformationResponseVoList[j].id) {
                newCollectInfoList[m].answer = datalist[i].joinCollectInformationResponseVoList[j].answer
                break
              }
            }
          }
          datalist[i].newCollectInfoList = newCollectInfoList
        }
        let successData = []
        if(datalist.length > 0) {
          let list = (datalist || []).map((item, key) => { // 通过 map 方法遍历，组装数据成上面的格式
            return {
              index: key + 1,
              nickname: item.nickname,
              username: item.username,
              createTime: item.createTime,
              placeSuccessTime: item.placeSuccessTime,
              signTime: item.signTime ? '是' : '否',
              distributionUserName: item.distributionUserName || '',
              custom1: item.newCollectInfoList.length > 0 ? item.newCollectInfoList[0].answer : '',
              custom2: item.newCollectInfoList.length > 1 ? item.newCollectInfoList[1].answer : '',
              custom3: item.newCollectInfoList.length > 2 ? item.newCollectInfoList[2].answer : '',
              custom4: item.newCollectInfoList.length > 3 ? item.newCollectInfoList[3].answer : '',
              custom5: item.newCollectInfoList.length > 4 ? item.newCollectInfoList[4].answer : '',
              custom6: item.newCollectInfoList.length > 5 ? item.newCollectInfoList[5].answer : '',
              custom7: item.newCollectInfoList.length > 6 ? item.newCollectInfoList[6].answer : '',
              sourceClubName: this.detail.isJointActivity === 2 ? item.sourceClubName : '',
              firstJoin: item.firstJoin ? '是' : '否',
              joinCount: item.joinCount,
              identity: this.formatIdentity(item.identity),
              nonMemberAmount: item.nonMemberAmount ? item.nonMemberAmount.toFixed(2) : '-',
              isOpenDisclaimer: item.isOpenDisclaimer === 1 ? '未开启' : '是',
              collectInsurance: this.detail.isOpenAccidentInsurance === 2 ? ( item.collectInsurance.length > 0 ? item.collectInsurance.join('；') : '未领取' ) : ''
            }
          })
          successData = Export2Excel.formatJson(filterVal, list);
          successData.unshift(header)
          returnData.merges = []
        } else {
          let newArray =  Array.apply(null, Array((isSuccess ? 9 : 8) + joinCollectInformationResponseVoList.length).map(()=> ''))
          newArray[0] = isSuccess ? '无报名用户' : '无候补用户'
          successData.push(newArray)
          successData.unshift(header)
          const arrabc = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"]
          let insuranceType = 0 // 0.无保险 2.旧保险 3.新保险
          if(this.detail.isOpenInsurance == 2) {
            insuranceType = 1
          } else if(this.detail.isOpenAccidentInsurance == 2) {
            insuranceType = 2
          }
          returnData.merges = [`A${insuranceType == 1 ? '9' : insuranceType == 2 ? '10' : '8'}:${arrabc[(isSuccess ? 9 : 8) + joinCollectInformationResponseVoList.length - 1]}${insuranceType == 1 ? '9' : insuranceType == 2 ? '10' : '8'}`]
        }
        returnData.multiHeader = [
          ["活动：", this.detail.activityName],
          ["活动地址：", this.detail.address.split('|')[1]],
          ["活动时间：", `${ this.detail.startTime.substring(0, 16).replace(/-/g, '/') } — ${ this.detail.endTime.substring(0, 16).replace(/-/g, '/')}`],
          ["报名截止：", this.detail.joinEndTime.substring(0, 16).replace(/-/g, '/')],
          ["活动组别：", detail.activityFeeBranchName],
          ["表格内容：", isSuccess ? "成功报名活动的用户" : "候补活动的用户"]
        ]
        if(this.detail.insuranceOpenTime && (this.detail.isOpenInsurance == 2 || this.detail.isOpenAccidentInsurance == 2)) {
          returnData.multiHeader.push([
            "开启保险链接时间：", this.detail.insuranceOpenTime.substring(0, 16).replace(/-/g, '/')
          ])
        }
        returnData.multiHeader.push([
          "是否开启名额转让：", this.detail.isOpenQuotaTransfer === 2 ? '是' : '否'
        ])
        returnData.data= successData
        returnData.sheetname = `${detail.activityFeeBranchName}-${isSuccess ? '成功报名用户' : '候补用户'}`
        return returnData
      },
    }
  }
</script>

<style lang="scss" scoped>
  .back {
    width: 55px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    .back-text {
      color: #1465CF;
      font-size: 16px;
      margin-left: 5px;
    }
  }
  .activity-detail {
    width: 790px;
    min-height: 165px;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 16px;
    .title{
      color: #444;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .msg-line {
      font-size: 14px;
      margin-top: 10px;
      .label {
        color: #888;
      }
      .value {
        color: #333;
      }
    }
  }
  .activity-group {
    width: 790px;
    height: 50px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    overflow-x: auto;
    .group-item {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      margin-right: 80px;
      cursor: pointer;
      .group-name {
        min-width: 50px;
        color: #333;
        font-size: 14px;
        text-align: center;
        white-space: nowrap;
        word-break: break-all;
      }
      .bottom-line {
        width: 50px;
        height: 2px;
        border-radius: 2px;
        background-color: #1465CF;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .joiner-data {
    width: 790px;
    border-radius: 5px;
    background-color: white;
    padding: 20px 22px 10px 22px;
    box-sizing: border-box;
    margin-bottom: 16px;
    .table-data {
      margin-bottom: 25px;
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        .title {
          color: #444;
          font-size: 18px;
          font-weight: bold;
        }
        .download {
          display: flex;
          align-items: center;
          color: #444;
          font-size: 14px;
          cursor: pointer;
          &__item {
            display: flex;
            align-items: center;
            margin-left: 30px;
          }
        }
      }
    }
  }
  .empty {
    width: 100%;
    height: 172px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #888;
    background-color: white;
  }
  .table-header {
    font-size: 12px;
    color: #333;
    position: relative;
    .tooltip{
      position: absolute;
      top: 4px;
      right: 4px;
    }
  }
  .table-text {
    color: #333;
    font-size: 12px;
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
  }
  .table-text1 {
    color: #333;
    font-size: 12px;
  }
  .el-range-editor.el-input__inner {
    padding: 2px 4px;
    font-size: 12px;
    color: #333;
  }
  .el-date-editor .el-range__icon {
    font-size: 14px;
    margin-left: 3px;
    color: #333;
    float: left;
    line-height: 32px;
    margin-bottom: 6px;
  }
  .el-date-editor .el-range-input {
    color: #333;
    font-size: 12px;
  }
</style>